.annotation {
  color: var(--base08);
}

.linkedAnnotation {
  composes: annotation;
  text-decoration: underline;
  cursor: pointer;
}

.comment {
  color: var(--base03);
}

.imageJsonValue {
  max-height: 50px;
  display: inline-block;
  margin-right: 1ch;
}

.noSelect {
  user-select: none;
}
