.p {
  margin-bottom: 1em;
}

.h1,
.h2,
.h3,
.h4,
.h5 {
  margin: 0.8em 0;
  line-height: 1.3;
}

.h1 {
  font-size: 28.83px;
}

.h2 {
  font-size: 25.63px;
}

.h3 {
  font-size: 22.78px;
}

.h4 {
  font-size: 20.25px;
}

.h5 {
  font-size: 18px;
}

.small {
  font-size: 14px;
}
