@use "../../variables";

$duration: 200ms;
$bg: lighten(variables.$background, 3%);

.layer {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(black, 0.3);
  z-index: 999;
}

.data {
  position: absolute;
  overflow: auto;
  top: 0;
  bottom: 0;
  right: 0;
  background: $bg;
  padding: 10px;
  box-shadow: 0 0px 20px rgba(black, 0.3);
  will-change: transform;
}

:global(.global-dataview-animation-enter) {
  .root {
    background: rgba(black, 0);
  }

  .data {
    transform: translate(100%);
  }
}

:global(.global-dataview-animation-enter.global-dataview-animation-enter-active) {
  .root {
    background: rgba(black, 0.3);
    transition: background $duration;
  }

  .data {
    transform: translate(0%);
    transition: transform $duration;
  }
}

:global(.global-dataview-animation-exit) {
  .root {
    background: rgba(black, 0.3);
  }

  .data {
    transform: translate(0%);
  }
}

:global(.global-dataview-animation-exit.global-dataview-animation-exit-active) {
  .root {
    background: rgba(black, 0);
    transition: background $duration;
  }

  .data {
    transform: translate(100%);
    transition: transform $duration;
  }
}
