@use "../../views/ApiRequest/variables";

.urlBar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
}

.url {
  flex: 1 1 auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 8px;
  padding: 6px 0;

  &,
  .urlPrefix {
    font-family: "Roboto Mono", monospace;
  }
}

.urlPrefix {
  opacity: 0.5;
}

.urlActions {
  margin-left: 16px;
}

.description {
  max-width: 800px;
}

.requestEditor {
  width: 100%;
  background: variables.$apiRequestEditorBg;
  border-radius: 3px;
  padding: 16px;
}

.params {
  margin-top: 16px;
}

.params + .params {
  margin-top: 32px;
}

.submitButton {
  --bg-color: #e67e22;

  color: white;
  background: var(--bg-color);
  border: none;
  font-size: 16px;
  border-radius: 2px;
  padding: 6px 10px;
  cursor: pointer;
  line-height: 1;
  border: 2px solid var(--bg-color);
  outline: none !important;
  transition: 100ms ease-in-out background;

  &:hover {
    --bg-color: #{lighten(#e67e22, 15%)};
  }

  &:active {
    --bg-color: #{lighten(#e67e22, 5%)};
  }

  &:focus {
    border-color: desaturate(lighten(variables.$apiRequestEditorBg, 90%), 5%);
  }

  &[disabled] {
    opacity: 0.5;
    --bg-color: #e67e22;
  }
}

.actionButton {
  background: none;
  border: none;
  color: inherit;
  padding: 8px;
  margin-left: 8px;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 100ms ease-in-out background;
  outline: none !important;

  &:hover {
    background: rgba(white, 0.15);
  }

  &:active {
    background: rgba(white, 0.05);
  }

  &:focus {
    box-shadow: inset 0 0 0 2px
      desaturate(lighten(variables.$apiRequestEditorBg, 90%), 5%);
  }
}
