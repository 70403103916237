$collectDrawerWidth: 270px;

.root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.body {
  position: relative;
  z-index: 1;
  flex: 1;
  display: flex;
}

.main {
  height: 100%;
  width: 100%;
  padding: 10px;
  overflow: auto;
}

.items {
  display: flex;
  flex-wrap: wrap;
}

.item {
  min-width: 200px;
  flex: 0 0 auto;
  padding: 5px;
  width: 20%;

  @media screen and (max-width: 1020px) {
    width: 25%;
  }

  @media screen and (max-width: 820px) {
    width: 33.3333%;
  }

  @media screen and (max-width: 620px) {
    width: auto;
    flex: 1 0 50%;
  }
}

.collectDrawer {
  width: $collectDrawerWidth;
  height: 100%;
  padding: 10px;
}

.collectDrawerInner {
  width: 100%;
  height: 100%;
  padding: 10px;
  background: rgba(white, 0.075);
  border-radius: 2px;
  overflow: scroll;
}

.moreResultsWrap {
  text-align: center;
  font-size: 0;
  margin: 30px;
}

.moreResults {
  font-size: 16px;
  text-align: center;
  padding: 15px 30px;
  background: rgba(white, 0.075);
  display: inline-block;
}

.moreResultsButton {
  background: none;
  font-size: inherit;
  color: inherit;
  border: none;
  text-decoration: underline;
  cursor: pointer;
}
