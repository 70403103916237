@use "../../variables";

.root {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(black, 0);
  transition: 200ms ease-in-out background;
  z-index: 990; // modesty
  // display: none;
  pointer-events: none;

  &.visible {
    background: rgba(black, 0.3);
    pointer-events: initial;
  }
}

.drawer {
  background: variables.$overlay-background;
  height: 100%;
  overflow: auto;
  width: 100%;
  max-width: 500px;
  box-shadow: none;
  transition: 200ms ease-in-out transform;
  transform: translateX(-100%);

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lighten(#16171a, 20%);
    border-radius: 5px;
  }

  .visible & {
    transform: translateX(0);
    box-shadow: 5px 0px 20px rgba(black, 0.3);
  }
}

.title {
  margin: 0 0 16px 0;
}

.header {
  background: none !important;
  // opacity: 0;
}

.hr {
  border: none;
  height: 1px;
  margin: 0 8px 0 16px;
  background: rgba(white, 0.2);
}

.body {
  padding: 16px 6px 16px 16px;
}

.group {
  & + & {
    margin-top: 32px;
  }
}

.groupTitle {
  margin: 0;
  margin-bottom: 10px;
}

.apiRequest {
  color: inherit;
  text-decoration: none;
  padding: 16px;
  background: rgba(white, 0.05);
  display: block;
  border-radius: 3px;
  transition: 50ms ease-in-out background;

  &:hover {
    background: rgba(white, 0.075);
  }

  & + & {
    margin-top: 16px;
  }
}

.requestTitle {
  margin: 0;
  margin-bottom: 8px;
}

.requestDescription {
  display: -webkit-box;
  margin: 0;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.titleSplit {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  // background: blue;
}

.chevron {
  position: relative;
  transform: translateX(0px);
  transition: 150ms ease-in-out transform;

  .apiRequest:hover & {
    transform: translateX(4px);
  }
}

.searchField {
  composes: textInput from "../ParameterEditor/types/styles.module.scss";
  font-family: inherit;
  margin: 0 0 32px 0;
  width: 100%;
}

.noResults {
  margin-top: 16px;
  text-align: center;
  font-style: italic;
}

.explainer {
  position: absolute;
  top: 0;
  left: 500px;
  max-width: 800px;
  transition: 50ms ease-in-out opacity;
  opacity: 0;
  pointer-events: none;
  margin: 16px;
  padding: 16px;
  background: invert(variables.$overlay-background);
  color: variables.$overlay-background;
  box-shadow: 5px 0px 20px rgba(black, 0.3);
  border-radius: 3px;

  .visible & {
    opacity: 1;
    pointer-events: initial;
  }

  h2 {
    margin-top: 0;
  }

  &,
  * {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
}

.newLabel {
  text-transform: uppercase;
  font-size: 0.8em;
  padding: 2px 8px;
  background: #6ab04c;
  border-radius: 3px;
  color: white;
  text-shadow: 0 0 5px rgba(black, 30%);

  display: inline-block;
  margin-right: 0.5ch;
}
