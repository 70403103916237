@use "../../views/ApiRequest/variables";

.root {
  font-size: 16px;
  height: 100vh;
  // background: #7f8c8d;

  code {
    font-family: monospace;
  }

  &,
  * {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
}

.root {
  display: flex;
  flex-direction: column;
}

.request {
  // background: #34495e;
  flex: 0 0 auto;
  margin: 16px;
}

.response {
  // background: #9b59b6;
  flex: 1 1 auto;
  overflow: auto;
  margin: 16px;
  margin-top: 0;

  // background: #0f1217;
  // border-radius: 3px;
  // padding: 16px;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: transparent;
    margin-top: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lighten(#16171a, 20%);
    border-radius: 5px;
  }
}

.header {
  margin: -16px;
  margin-bottom: 16px;
}

.headerBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerApiName {
  margin-top: -2px;
  cursor: pointer;

  strong {
    font-weight: 500;
  }
}

.headerbutton {
  cursor: pointer;
  padding: 5px 16px;
  color: inherit;
  border: none;
  border-radius: 2px;
  font-size: 16px;
  font-family: "Titillium Web", sans-serif;
  margin-top: -1px;
  transition: 100ms ease-in-out background;
  outline: none !important;
  text-decoration: none;

  background: #2e2f31;

  &:hover {
    background: lighten(#2e2f31, 15%);
  }

  &:active {
    background: lighten(#2e2f31, 5%);
  }

  &:focus {
    box-shadow: inset 0 0 0 2px
      desaturate(lighten(variables.$apiRequestEditorBg, 90%), 5%);
  }

  & + & {
    margin-left: 1em;
  }
}
