@use "../../../variables";

$bg: lighten(variables.$background, 3%);

.player {
  display: flex;
  line-height: 1;
  align-items: center;
  padding: 4px 0;
  margin-left: -4px;
}

.playerAccessory {
  margin-right: 8px;
}

.playerEmblem {
  height: 35px;
  width: 35px;
}

.playerName {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 4px;
}

.playerDescription {
  font-size: 0.9em;
}

.itemWell {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 8px;
}

.inlineDataView {
  grid-column: 1 / -1;
  padding: 8px;
  background: $bg;
}
