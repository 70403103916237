@use "../../views/ApiRequest/variables";

.table {
  width: 100%;
  border-collapse: collapse;
}

td {
  border: 1px solid lighten(variables.$apiRequestEditorBg, 15%);
  padding: 8px;
}

thead {
  font-weight: 500;
  text-transform: uppercase;
}

.title {
  font-size: 1em;
  margin: 0 0 8px 0;
  font-weight: 500;
}
