$bg: #16171a;
$textColor: #ffffff;

.main {
  display: flex;
  background: $bg;
  font-size: 20px;
  padding: 15px;
  color: $textColor;
}

.mainMain {
  position: relative;
  flex: 1 0 auto;
}

.searchField {
  width: 100%;
  background: none;
  transition: all 150ms ease-in-out;
  font-size: inherit;
  color: white;
  border: none;
  outline: none !important;
  margin-left: 15px;
}

.logoish {
  display: flex;
  align-items: center;
}

.logo {
  height: 1.3em;
  margin-right: 0.5em;
}

._buttonBase {
  height: 33px;
  width: 33px;
  cursor: pointer;
  transition: 100ms ease-in-out;
  outline: none !important;
}

.bigButton {
  composes: _buttonBase;
  background: #2e2f31;
  font-size: 20px;
  color: $textColor;
  border: 1px solid #2e2f31;
  border-radius: 2px;

  &:hover {
    background: #393a3c;
    border-color: #393a3c;
  }
}

.buttonInner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bigButtonActive {
  composes: bigButton;
  background: $textColor !important;
  border-color: $textColor !important;
  color: #2e2f31 !important;
}

.filterButton {
  composes: bigButton;
  padding: 1px 7px 2px 7px;
  width: auto;
  font-size: 15px;
  text-decoration: none;

  & + & {
    margin-left: 1em;
  }
}
.languageButton {
  composes: filterButton;
  background: none;
  border: none;
  position: relative;
  overflow: hidden;
}

.languageDropdown {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  opacity: 0;
}

.filterIcon {
  font-size: 1em;
  margin-right: 0.5ch;
}

.subtleButton {
  composes: _buttonBase;
  font-size: 15px;
  background: none;
  color: rgba(white, 0.4);
  border: none;
  margin-right: 10px;
  transition: 100ms ease-in-out;

  &:hover {
    color: rgba(white, 0.6);
  }
}

.subtleButtonActive {
  composes: subtleButton;
  color: rgba(white, 0.95) !important;
}

.appliedFilter {
  font-size: 14px;
  background: rgba(white, 0.1);
  display: inline-block;
  margin-left: 15px;
  padding: 1px 5px;
  padding-left: 8px;
  padding-right: 0;
  border-radius: 2px;
}

.filterDrawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 350px;
  z-index: 10;
  box-shadow: 0 2px 10px 0 #000;
  padding: 10px;
  transition: 200ms ease-in-out;
}

.closeButton {
  background: none;
  border: none;
  padding: 2px;
  color: inherit;
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 5px;
  font-size: 12px;

  &:hover {
    background: rgba(white, 0.05);
  }
}

.closeIcon {
  color: inherit;
}

.filterModalBase {
  content: "filterModalBase";

  .filterDrawer {
    transform: translateX(100%) !important;
  }
}

.filterModalAfterOpen {
  content: "filterModalAfterOpen";

  .filterDrawer {
    transform: translateX(0) !important;
  }
}

.filterModalBeforeClose {
  content: "filterModalBeforeClose";

  .filterDrawer {
    transform: translateX(100%) !important;
  }
}

.filterModalOverlayBase {
  content: "filterModalOverlayBase";
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0) !important;
  transition: 200ms ease-in-out;
}

.filterModalOverlayAfterOpen {
  content: "filterModalOverlayAfterOpen";
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.filterModalOverlayBeforeClose {
  content: "filterModalOverlayBeforeClose";
  background-color: rgba(0, 0, 0, 0) !important;
}

.spinnerIcon {
  position: absolute;
  right: 7px;
  top: 7px;
}

.emojiImage {
  height: 1.2em;
  display: inline-block;
  margin-right: 0.75ch;
}
