.root {
  background: #16171a;
  color: lighten(invert(#16171a), 15%);

  h2 {
    margin-top: 0;
  }
}

.dropdown {
  font-size: 14px;
  color: black;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  padding-right: 20px;
  padding-top: 15px;
  color: white;
  font-size: 20px;
  background: none;
  border: none;
}

.filterLabel {
  margin-bottom: 5px;
  display: block;
}

.filterSet + .filterSet {
  margin-top: 20px;
}
