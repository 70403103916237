.root {
  margin: 32px 0 16px 0;
}

.button {
  border: none;
  color: white;
  font-family: inherit;
  font-size: inherit;
  padding: 6px 16px;
  cursor: pointer;
  position: relative;

  background: #2e2f31;

  &:hover {
    background: lighten(#2e2f31, 15%);
  }

  &.active {
    cursor: initial;
  }

  &.active,
  &:active {
    background: lighten(#2e2f31, 10%);
  }

  &:focus {
    z-index: 3;
  }

  &:first-of-type {
    border-radius: 3px 0 0 3px;
  }

  &:last-of-type {
    border-radius: 0 3px 3px 0;
  }
  &.copyConfirm {
    color: transparent;
  }
}

div.copyConfirm {
  position: absolute;
  left: 0;
  right: 0;
  top: 6px;
  bottom: 6px;
  color: white;
}
