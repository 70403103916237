.root {
  padding: 16px;
  font-size: 16px;

  code {
    font-family: monospace;
  }

  p {
    max-width: 800px;
  }

  &,
  * {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
}

.api + .api {
  margin-top: 32px;
}

.requiredTag {
  color: #f39c12;
  display: inline-block;
  margin-left: 1ch;
}
