.title {
  margin-top: 32px;
  margin-bottom: 16px;
  font-size: 1.3em;
}

.heightLimitedWell {
  padding: 8px;
  overflow: auto;
  background: rgba(black, 0.2);
  border-radius: 3px;
  max-height: 210px;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: transparent;
    margin-top: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lighten(#16171a, 35%);
    border-radius: 5px;
  }
}

// items

.category {
  margin-top: 32px;
  margin-bottom: 40px;
}

.socketList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  gap: 8px;
}

.socket {
  composes: heightLimitedWell;
}

.plug {
  & + & {
    margin-top: 8px;
  }
}

.randomPlugsHeader {
  opacity: 0.75;
  margin-top: 8px;
  margin-bottom: 4px;
}

.altPlug {
  composes: plug;
  opacity: 0.5;
}

.itemGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 8px;
}

.categoryItems {
  composes: itemGrid heightLimitedWell;
  margin: 0 16px;
}

.tag {
  display: inline-block;
  font-weight: normal;
  margin-left: 1ch;
  font-size: 15px;
  padding: 3px 5px;
  line-height: 1;
  border-radius: 3px;
  border: 1px solid rgba(white, 0.2);
  background: rgba(white, 0.05);
}

.containWidth {
  max-width: 700px;
}

.prose {
  composes: containWidth;
  white-space: pre-line;
  line-height: 1.15;
  max-width: 700px;
}

.quoteProse {
  composes: prose;
  margin-left: 8px;
  padding-left: 16px;
  border-left: 2px solid rgba(white, 0.3);
  font-style: italic;
  opacity: 0.8;
}

.inlineIcon {
  height: 1.1em;
  width: auto;
  vertical-align: middle;
  display: inline-block;
  margin-right: 0.25ch;
}

.questStep {
  composes: containWidth;
  opacity: 0.7;

  margin-top: 32px;
  margin-bottom: 40px;
}

.activeQuestStep {
  composes: questStep;
  opacity: 1;
}

.questStepName {
  margin-bottom: 8px;
}

.questStepDescription {
  composes: prose;
  margin-top: 0;
}

.plainLink {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
