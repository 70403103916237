@use "../../variables";

$bg: lighten(variables.$background, 3%);

.titleIcon {
  height: 1.5em;
  display: inline-block;
  margin-right: 0.75ch;
  vertical-align: middle;
}

.code {
  composes: small from "styles/typography.module.scss";
  border-radius: 3px;
  font-family: "Roboto Mono", monospace;
  font-weight: 400;
  margin-left: 1em;
  padding: 3px 5px;
  background: lighten($bg, 5%);
  border: 1px solid lighten($bg, 15%);
}

.itemDescription {
  max-width: 600px;
  white-space: pre-wrap;
}

.rawJson {
  font-size: 14px;
  background: rgba(black, 0.2);
  border-radius: 3px;

  &,
  * {
    font-family: "Fira Mono";
  }
}

.limitHeight {
  max-height: 80vh;
  overflow: auto;
}
