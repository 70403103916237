@use "../../variables";

$iconSize: 3.125em;
$substituteColour: #494a4c;

.root {
  display: flex;
  color: inherit;
  text-decoration: none;
  position: relative;
  z-index: 2;

  > * {
    position: relative;
    z-index: 2;
  }

  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    background: rgba(#fafafa, 0);
    border-radius: 2px;
  }
}

.collected {
  composes: root;
  color: variables.$background;

  &:before {
    background: #fafafa;
  }
}

.icon {
  background: lighten(variables.$background, 5%);
  border-radius: 2px;
}

.enhancedIcon {
  outline: 2px solid #eade8b;
  border-radius: 200px;
}

.accessory {
  margin-right: 10px;
}

.icon {
  height: $iconSize;
  display: block;
}

.substituteIcon {
  height: 2.5em;
  width: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid $substituteColour;
  color: lighten($substituteColour, 50%);
  font-size: 20px;
}

.main,
.name,
.children,
.itemType {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.children,
.itemType {
  opacity: 0.75;
  font-size: 0.85em;
}

.extraIndicator {
  display: inline-block;
  height: 0.55em;
  width: 0.55em;
  border-radius: 100%;
  background-color: #f39c12;
}

.name span {
  vertical-align: middle;
  line-height: 1;
}
