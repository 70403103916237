@use "./variables";

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: variables.$background;
  color: white;
}

* {
  font-family: "Titillium Web", sans-serif;
  box-sizing: border-box;
}

code {
  font-family: "Roboto Mono", monospace;
}
