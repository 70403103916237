@use "../../../views/ApiRequest/variables";

$accessoryWidth: 32px;

.textInputWrapper {
  position: relative;
}

.inputAccessory {
  position: absolute;
  right: -5px;
  top: -5px;
  bottom: -5px;
  width: $accessoryWidth;
  background: none;
  border: none;
  color: inherit;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;

  &:hover:not(.active) {
    background: rgba(#e67e22, 50%);
    color: white;
  }

  &.active {
    color: lighten(#e67e22, 10%);
  }
}

.textInput {
  width: calc(100% + 10px);
  color: inherit;
  background: none;
  border: none;
  font-size: inherit;
  padding: 0;
  font-family: "Roboto Mono", monospace !important;
  padding: 5px;
  margin: -5px;
  outline: none !important;
  border-bottom: 1px solid
    desaturate(lighten(variables.$apiRequestEditorBg, 25%), 5%);
  transition: background 50ms ease-in-out;

  tr:hover & {
    background: desaturate(lighten(variables.$apiRequestEditorBg, 3%), 5%);
  }

  &:hover,
  &:active {
    background: desaturate(
      lighten(variables.$apiRequestEditorBg, 6%),
      5%
    ) !important;
  }

  &:focus {
    outline: 2px solid
      desaturate(lighten(variables.$apiRequestEditorBg, 50%), 5%) !important;
    border-bottom-color: transparent;
  }

  &.withAccessory {
    padding-right: $accessoryWidth + 5px;
  }
}

.reselectInput {
  width: 100%;
  color: black;
}

.specialValue {
  font-size: 0.9em;
  padding: 4px 10px;
  background: desaturate(lighten(variables.$apiRequestEditorBg, 6%), 5%);
  border-radius: 3px;
}

.inlineButton {
  composes: headerbutton from "../../../views/ApiRequest/styles.module.scss";
}

.suggestions {
  position: absolute;
  top: calc(100% + 8px);
  width: 100%;
  background: variables.$suggestionBg;
  z-index: 999;
  padding: 8px;
  box-shadow: 5px 0px 20px rgba(black, 0.3);

  &:before {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-bottom: 10px solid variables.$suggestionBg;
    content: "";
    position: absolute;
    top: -8px;
    right: 1px;
  }
}

.suggestionExplainer {
  margin: 4px 0 12px 0;
}

.profileButton {
  appearance: none;
  background: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  border: none;
  width: 100%;
  text-align: left;
  padding: 0;
}
